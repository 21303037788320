
const now = new Date();
const interval = 24 * 60 * 60 * 1000;
// set target time to 2am
let target = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 2, 0, 0, 0);
let LISTENER = () => {};

// if target time is less than now, add a day (2am tomorrow)
if (target < now) {
    target = dateAdd(target, 'day', 1)
}

const timeout = target.getTime() - now.getTime();
console.log('closing screenshots in: ' + msToString(timeout));

setTimeout(() => {
    LISTENER();
    setInterval(() => {
        LISTENER();
    }, interval)
}, timeout);

function setListener(listener) {
    if (typeof listener === 'function') {
        LISTENER = listener;
    }
}

function msToString(howLong) {
    const hours = parseInt(howLong / (60 * 60 * 1000));
    howLong = howLong - (hours * 60 * 60 * 1000);
    const minutes = parseInt(howLong / (60 * 1000));
    howLong = howLong - (minutes * 60 * 1000);
    const seconds = Math.round(howLong / 1000);

    let message = '';

    if (hours) {
        message = `${hours} hours `;
    }

    message += `${minutes} minutes `;
    message += `${seconds} seconds`;

    return message;
}

/**
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
 * https://stackoverflow.com/a/1214753/18511
 * 
 * @param date  Date to start with
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 */
function dateAdd(date, interval, units) {
    if(!(date instanceof Date))
        return undefined;

    const ret = new Date(date); //don't change original date
    const checkRollover = () => {
        if(ret.getDate() !== date.getDate()) {
            ret.setDate(0);
        }
    };

    switch(String(interval).toLowerCase()) {
        case 'year'   :  ret.setFullYear(ret.getFullYear() + units); checkRollover();  break;
        case 'quarter':  ret.setMonth(ret.getMonth() + 3 * units); checkRollover();  break;
        case 'month'  :  ret.setMonth(ret.getMonth() + units); checkRollover();  break;
        case 'week'   :  ret.setDate(ret.getDate() + 7 * units);  break;
        case 'day'    :  ret.setDate(ret.getDate() + units);  break;
        case 'hour'   :  ret.setTime(ret.getTime() + units * 60 * 60 * 1000);  break;
        case 'minute' :  ret.setTime(ret.getTime() + units * 60 * 1000);  break;
        case 'second' :  ret.setTime(ret.getTime() + units * 1000);  break;
        default       :  break;
    }

    return ret;
}

const eventTimerInterface = {
    setListener,
};

export default eventTimerInterface;