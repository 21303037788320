
const DEFAULT_INTERVAL = 30 * 1000; // get data every 30 seconds

const OPTIONS = {
    CLIENT: '',
    DOWNONLY: '',
    USER: '',
    INTERVAL: DEFAULT_INTERVAL
};

let TIMER = null;
let LISTENER = () => {};

const location = new URL(window.location); 
const client = location.hostname.split('.', 3);
let GETDATAURL = 'https://img.support.dev.ab-net.us/data';

if (client.indexOf('localhost') === -1 && client.indexOf('dev') === -1) {
    GETDATAURL = 'https://img.support.prod.ab-net.us/data';
}

function setOptions(options = {}) {
    OPTIONS.CLIENT = (options && options.client) ? options.client : '';
    OPTIONS.DOWNONLY = (options && options.downonly) ? options.downonly : '';
    OPTIONS.USER = (options && options.user) ? options.user : '';
    OPTIONS.INTERVAL = (options && options.interval) ? options.interval * 1000 : 0;

    if (!OPTIONS.INTERVAL) {
        OPTIONS.INTERVAL = localStorage.getItem('interval') || DEFAULT_INTERVAL;
    }

    localStorage.setItem('interval', OPTIONS.INTERVAL);

    stopTimer();
    startTimer();
}

async function getClientData() {
    const url = `${GETDATAURL}?client=${OPTIONS.CLIENT}&downonly=${OPTIONS.DOWNONLY}&user=${OPTIONS.USER}`;

    const response = await fetch(url);
    const result = await response.json();

    LISTENER(result);
}

function setListener(listener) {
    if (typeof listener === 'function') {
        LISTENER = listener;
    }
}

async function startTimer() {
    if (!TIMER) {
        TIMER = setInterval(async function() {
            await getClientData();
        }, OPTIONS.INTERVAL);
        await getClientData();
    }
}

async function refreshData() {
    await getClientData();
}

function stopTimer() {
    if (TIMER) {
        clearInterval(TIMER);
        TIMER = null;
    }
}

function getInterval() {
    let interval = localStorage.getItem('interval');

    if (interval) {
        interval = parseInt(interval) / 1000;
    }
    else {
        interval = DEFAULT_INTERVAL / 1000;
    }
    
    return interval;
}

const dynamoInterface = {
    setOptions,
    setListener,
    refreshData,
    stopTimer,
    getInterval
};

export default dynamoInterface;