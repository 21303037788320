const config = {
    OFFLINE_TIMEOUT: 30 * 60 * 1000, // 30 minutes
    WARNING_TIMEOUT: 15 * 60 * 1000, // 15 minutes
    SCREENSHOT_INTERVAL: 5 * 60 * 1000, // 5 minutes
    SHEETZUSERS: [
        'sheetz-support',
        'sheetz-storesolutions'
    ],
    USERS: [
        'englefield-support',
        'friendship-support',
        'rutters-support',
        'weigels-support'
    ]
};

export default config;