import { set, get } from 'idb-keyval';
import $ from 'jquery';
import _ from 'lodash';
import Config from '../Config';

let CLIENT_DATA = {};
const OPTIONS = {
    CLIENT: '',
    DOWNONLY: '',
    USER: ''
};

const DEV = [
    'dev1',
    'qa1'
];
const PROD = [
    'ega',
    'open',
    'parkland',
    'rutters',
    'sheetz',
    'sheetz1',
    'ta'
];
const SHEETZ = [
    'sheetz',
    'sheetz1' 
];

const location = new URL(window.location); 
const client = location.hostname.split('.', 3);
let CLIENTS = DEV;

if (client.indexOf('localhost') === -1 && client.indexOf('dev') === -1) {
    CLIENTS = PROD;
}

async function setClientData(client, data) {
    CLIENT_DATA[client] = data;
    await set(client, data);
}

async function setOptions(options = {}) {
    OPTIONS.CLIENT = (options && options.client) ? options.client : '';
    OPTIONS.USER = (options && options.user) ? options.user : '';

    await refreshData();
}

async function refreshData() {
    if (OPTIONS.CLIENT) {
        const data = await get(OPTIONS.CLIENT);

        if (data) {
            // already have data from the client, refresh
            setClientData(OPTIONS.CLIENT, data);
            fetchClientData(OPTIONS.CLIENT);
        }
        else {
            // don't have data yet, wait for it
            await fetchClientData(OPTIONS.CLIENT);
        }
    }
    else {
        if (Config.SHEETZUSERS.indexOf(OPTIONS.USER) !== -1) {
            CLIENTS = SHEETZ;
        }

        const clientsHaveData = await checkAllClientsData();

        if (clientsHaveData) {
            // already have data from the clients, refresh
            fetchAllCientsData();
        }
        else {
            // don't have data yet, wait for it
            await fetchAllCientsData();
        }
    }
}

async function checkAllClientsData() {
    let answer = true;

    for (var i = 0; i < CLIENTS.length; i++) {
        const client = CLIENTS[i];
        let data = await get(client);
        
        if (data) {
            setClientData(client, data);
        }
        else {
            answer = false;
        }
    }

    return answer;
}

function getDevice(row) {
    return find(row.client, row.macAddress);
}

function getServerVersion(client) {
    return find(client, '', 'server_version');
}

async function fetchClientData(client) {
    const url = `https://${client}.cms.ab-net.us/api/dumpdata`;
    
    try{
        const data = await fetch(url);

        if (data.ok) {
            setClientData(client, await data.json());
        }
        else {
            console.log('Render.fetchClientData error: ', client);
        }
    }
    catch (e) {
        console.log(`${e} ${url}`)
    }
}

async function fetchAllCientsData() {
    const promises = [];

    for (var i = 0; i < CLIENTS.length; i++) {
        let promise = fetchClientData(CLIENTS[i]);

        promises.push(promise);
    }

    return Promise.all(promises);
}

function find(client, macAddress, property) {
    let value = '';
    let clientData = '';

    clientData = CLIENT_DATA[client];

    if (!clientData) {
        return value;
    }

    if (property === 'server_version') {
        if (clientData.server_version) {
            return clientData.server_version;
        }

        return value;
    }

    if (!_.isArray(clientData)) {
        clientData = clientData.devices;
    }

    if (!clientData) {
        return value;
    }

    for (var i = 0; i < clientData.length; i++) {
        if (clientData[i]['device_macaddress'] === macAddress) {
            if (property) {
                value = clientData[i][property];
            }
            else {
                value = clientData[i];
            }
        }
    }

    return value;
}

function displayName(d, type, row) {
    return find(row.client, row.macAddress, 'display_name');
}

function deviceName(d, type, row) {
    const deviceName = find(row.client, row.macAddress, 'device_name');

    if (type === 'sort') {
        const parsed = parseInt(deviceName);
        
        if (parsed) {
            return parsed;
        }
    }

    return deviceName;
}

function deviceVersion(d, type, row) {
    // device_version comes from server datadump
    let version = find(row.client, row.macAddress, 'device_version');

    // deviceversion comes from apps version 3.1.26 and up
    if (row.deviceversion) {
        version = row.deviceversion;
    }

    if (version !== row.app_version) {
        version = $(error(version));
        version.attr('title', `Server Version: ${row.app_version}`);
        version = version[0].outerHTML;
    }

    return version;
}

function serverVersion(d) {
    if (!d) {
        return warning('3.1.25 or below')
    }

    return d;
}

function siteName(d, type, row) {
    const siteName = find(row.client, row.macAddress, 'site_name');

    if (type === 'sort') {
        const parsed = parseInt(siteName);
        
        if (parsed) {
            return parsed;
        }
    }

    return siteName;
}

function appName(d, type, row) {
    return find(row.client, row.macAddress, 'app_name');
}

function operatingSystem(d, type, row) {
    const operatingSystem = row.operatingSystem || '';
    const osVersion = row.osVersion || '';

    return `${operatingSystem} ${osVersion}`;
}

function logging(d) {
    if (!d || d === 'false') {
        return 'OFF';
    }

    return bold('ON');
}

function dateTime(epoch, type, row) {
    if (type === 'sort') {
        return parseInt(epoch);
    }

    const diff = Date.now() - parseInt(epoch);
    let date = new Date(epoch).toLocaleString('en');

    if (date === 'Invalid Date') {
        return '';
    }

    // if longer than offline timeout since last ping, add error
    if ((row && row.oopsscreen === 'true') || diff > Config.OFFLINE_TIMEOUT) {
        date = error(date);
    }
    else if (diff > Config.WARNING_TIMEOUT) {
        date = warning(date);
    }

    return date;
}

function screenshotButton(src) {
    if (!src) {
        return '<div class="screenshot" />';
    }

    let css = 'btn btn-secondary screenshot';
    let button = `<button type="button" class="${css}" data-src="${src}?${Date.now()}">Click to view</button>`;

    return button;
}

function screenshot(src, row) {
    if (!src) {
        return '<div class="screenshot" />';
    }

    let css = 'screenshot';

    if (row && row.screenshotinterval && parseInt(row.screenshotinterval) !== Config.SCREENSHOT_INTERVAL) {
        css += ' border';
    }

    let screenshot = `<img class="${css}" src="${src}" />`;

    if (row && row.ledstatus !== 'n/a' && row.ledstatus !== 'Led not found') {
        return `<div class="led">${screenshot}</div>`;
    }

    return screenshot;
}

function bold(text) {
    return `<span class="bold">${text}</span>`;
}

function warning(text) {
    return `<span class="warning">${text}</span>`;
}

function error(text) {
    return `<span class="error">${text}</span>`;
}

function versionText(text) {
    return `<span class="versiontext">${text}</span>`;
}

function links(d, type, row) {
    const allLink = `?client=${row.client}&downonly=`;
    const downLink = `?client=${row.client}&downonly=true`;

    return `<a href="${allLink}" class="viewall">All</a> | <a href="${downLink}" class="viewdown">Down</a>`;
}

const serverInterface = {
    setOptions,
    refreshData,
    getDevice,
    getServerVersion,
    Render: {
        displayName,
        deviceName,
        deviceVersion,
        serverVersion,
        siteName,
        appName,
        operatingSystem,
        logging,
        dateTime,
        screenshotButton,
        screenshot,
        warning,
        error,
        links,
        versionText
    }
}

export default serverInterface;
