const location = new URL(window.location); 
const client = location.hostname.split('.', 3);
let prefix = 'dev';
let LISTENER = () => {};

if (client.indexOf('localhost') === -1 && client.indexOf('dev') === -1) {
    prefix = 'prod';
}

// websocket constants
const WEBSOCKET_URL = `wss://ws.support.${prefix}.ab-net.us`;
const CONNECTION_RETRY = 1 * 1000; // if connection is lost, retry every 1 second

let retry = true;
let connection = null;

function connect() {
    if (connection && connection.readyState === WebSocket.OPEN) {
        return;
    }
    
    window.WebSocket = window.WebSocket || window.MozWebSocket;
    connection = new WebSocket(WEBSOCKET_URL);
    
    connection.onopen = () => {};

    connection.onmessage = msg => {
        LISTENER(msg);
    };

    connection.onerror = () => {
        connection.close();
    };

    connection.onclose = () => {
        if (retry) {
            setTimeout(() => {
                connect();
            }, CONNECTION_RETRY);

            connect();
        }
    };
}

function send(action, data) {
    if (connection && connection.readyState === WebSocket.OPEN) {
        const message = data || {};
        message.action = action;
        connection.send(JSON.stringify(message));
    }
    else {
        connection.close();
    }
}

function setListener(listener) {
    if (typeof listener === 'function') {
        LISTENER = listener;
    }
}

function close() {
    retry = false;
    connection.close();
    connection = null;
}

const webSocketInterface = {
    connect,
    send,
    setListener,
    close,
};

export default webSocketInterface;